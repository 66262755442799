import React from "react";

export const Login = React.lazy(() => import("../containers/Auth/Login"));
export const VerifyPhone = React.lazy(() =>
  import("../containers/Auth/VerifyPhone")
);
export const SetPassword = React.lazy(() =>
  import("../containers/Auth/SetPassword")
);
export const UploadLogo = React.lazy(() =>
  import("../containers/Auth/UploadLogo")
);
export const FirmsList = React.lazy(() => import("../containers/SuperAdminModule/Firms"));
export const AddFirm = React.lazy(() => import("../containers/SuperAdminModule/AddFirm"));
export const SpeedContract = React.lazy(() => import("../containers/SuperAdminModule/contracts"));
export const EditSpeedContract = React.lazy(() => import("../containers/SuperAdminModule/EditTerm"));
export const ContractDetails = React.lazy(() => import("../containers/SuperAdminModule/ContractDetails"));
export const UsersManagement = React.lazy(() => import("../containers/SuperAdminModule/Users"));


export const Cases = React.lazy(() => import("../containers/Cases"));
export const AddCase = React.lazy(() => import("../containers/Cases/AddCase/AddCase"));
export const CaseInfo = React.lazy(() =>
  import("../containers/Cases/CaseInfo")
);
export const Users = React.lazy(() => import("../containers/Users"));
export const ConfirmResetPassword = React.lazy(() =>
  import("../containers/Auth/ConfirmResetPassword")
);
export const EmailSendResetPassword = React.lazy(() =>
  import("../containers/Auth/EmailSendResetPassword")
);
export const ResetPassword = React.lazy(() =>
  import("../containers/Auth/ResetPassword")
);
export const Dashboard = React.lazy(() => import("../containers/Dashboard"));
export const AllTasks = React.lazy(() => import("../containers/Dashboard/allTasks"));
export const Invoices = React.lazy(() => import("../containers/Invoices"));
export const ReturnInvoiceList = React.lazy(() => import("../containers/Invoices/returnInvoicesList"));
export const CreateInvoice = React.lazy(() =>
  import("../containers/Invoices/addEditInvoice")
);
export const ShowInvoice = React.lazy(() =>
  import("../components/ShowInvoice")
);
export const FirmSettings = React.lazy(() => import("../components/settings/FirmSettings"));
export const Settings = React.lazy(() => import("../containers/Settings"));
export const FreeTrail = React.lazy(() => import("../containers/FreeTrail"));
export const Thanks = React.lazy(() =>
  import("../containers/FreeTrail/Thanks")
);
export const MobileVerification = React.lazy(() =>
  import("../containers/MobileVerification")
);
export const Client = React.lazy(() =>
  import("../containers/Client")
);
export const Clients = React.lazy(() =>
  import("../containers/Clients")
);
export const ClientDetails = React.lazy(() =>
  import("../containers/Clients/ClientDetails")
);
export const AddClient = React.lazy(() =>
  import("../containers/Clients/AddEditClient")
);
export const BlankPage = React.lazy(() =>
  import("../components/BlankPage")
);
export const LegalLibrary = React.lazy(() =>
  import("../containers/LegalLibrary")
);
export const landingPage = React.lazy(() =>
  import("../containers/LandingPage")
);
export const WhoWEAre = React.lazy(() => import("../containers/WhoWeAre"));
export const WhatAboutMustand = React.lazy(() => import("../containers/WhatAboutMustand"));
export const CommonQuestions = React.lazy(() => import("../containers/CommonQuestions"));
export const ContactUs = React.lazy(() => import("../containers/ContactUs"));
export const Blogs = React.lazy(() => import("../containers/Blogs"));
export const Blog1 = React.lazy(() => import("../containers/Blogs/Blog1"));
export const Blog2 = React.lazy(() => import("../containers/Blogs/Blog2"));
export const TermsAndConditions = React.lazy(() => import("../containers/TermsAndConditions"));
export const PrivacyPolicy = React.lazy(() => import("../containers/PrivacyPolicy"))
export const PrintTable = React.lazy(() =>
  import("../components/Print/printTable")
);
export const FormattedSheetInstructions = React.lazy(() =>
  import("../components/ImportFormattedSheet/formattedSheetInstructions")
);
export const AddPhoneNumber = React.lazy(() =>
  import("../containers/Contracts/CreateNewAccount/AddPhoneNumber")
);
export const AddOtp = React.lazy(() =>
  import("../containers/Contracts/CreateNewAccount/AddOtp")
);
export const AddPassword = React.lazy(() =>
  import("../containers/Contracts/CreateNewAccount/SetPassword")
);
export const AddIdentification = React.lazy(() =>
  import("../containers/Contracts/CreateNewAccount/AddIdentification")
);
export const CompleteProfile = React.lazy(() =>
  import("../containers/Contracts/CreateNewAccount/CompleteProfile")
);
export const Contracts = React.lazy(() =>
  import("../containers/Contracts/HomePage")
);
